// Here you can add other styles

// Sidebar
.sidebar-brand {
  justify-content: left;
}

.btn {
  --cui-btn-color: #ffffff;
}

.btn-link {
  --cui-btn-color: #1a73e8;
}